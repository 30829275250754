(function (factory) {
  typeof define === 'function' && define.amd ? define(factory) :
  factory();
}(function () { 'use strict';

  // import './scrolling.js';
  // import './hero.js';
  window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

  jQuery(document).ready(function($) {
      // alert('v1.2');

      //////////////////////////////////////////////////
      ////////////////  SLICK SLIDER  //////////////////
      let duration = parseInt($('.slider-fade').data('duration'));
      $('.slider-fade').slick({
          dots: true,
          infinite: true,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          arrows: false,
          autoplay: true,
          autoplaySpeed: duration,
      });

      // // Wait for the page to finish loading
      // $(window).on('load', function() {
      //     // Get the preloader element using jQuery
      //     var preloader = $('#preloader');
          
      //     // Set a delay of 3 seconds before hiding the preloader
      //     preloader.delay(2000).fadeOut(500);
      // });


      //////////////////////////////////////////////////
      ////////////////  jQuery  //////////////////
      //////////////////////////////////////////////////
      // // Function to set image dimensions based on viewport width
      // function setImageDimensions() {
      //     const viewportWidth = $(window).width();

      //     if (viewportWidth < 768) {
      //         $('.gsap-m-parallax').each(function() {
      //             const img = $(this);
      //             const imgWidth = img.width();
      //             const imgHeight = img.height();

      //             img.css({
      //             'width': imgWidth + 'px',
      //             'height': imgHeight + 'px'
      //             });
      //         });
      //         $('.gsap-m-parallax img').addClass('parallax-set');
      //     } else {
      //         $('.gsap-m-parallax').attr('style', '');
      //         $('.gsap-m-parallax img').removeClass('parallax-set');
      //     }
      // }

      // // Call the function on page load
      // setImageDimensions();

      // // Call the function on window resize
      // $(window).resize(function() {
      //     $('.gsap-m-parallax img').removeClass('parallax-set');
      //     $('.gsap-m-parallax').attr('style', '');
      //     setImageDimensions();
      // });

      // //////////////////////////////////////////////////
      // // set section heights
      // let section3Heights = [];
      // function setSectionHeight(){
      //     let setions = $('.section-3-image');
      //     setions.each(function() {
      //         section3Heights.push($(this).height());
      //         $(this).height($(this).height());
      //         $(this).find('.section-cont').css('position', 'absolute');
              
      //     })
      // }
      // setTimeout(setSectionHeight, 1000)
      // // setSectionHeight();

      function setSliderHeight(){
          // get viewport height
          const viewportHeight = $(window).height();
          // set slider height
          $('.section-hero').css('height', viewportHeight + 'px');
          // set slider overlay height
          let overlayHeight = viewportHeight - 20;
          $('#slider-overlay').css('height', overlayHeight + 'px');
      }
      setSliderHeight();

      // set hero logo position
      // function setHeroLogoPos(){
      //     let element = $('#hero-title')
      //     let logoOffset = $(window).height() - (element.offset().top) + 100;
      //     console.log(logoOffset)
      //     $('#logo-cont').css('bottom', logoOffset + 'px')
      // }
      // setHeroLogoPos();






      //////////////////////////////////////////////////
      ////////////////  GSAP  //////////////////
      //////////////////////////////////////////////////


      // gsap.registerPlugin(ScrollTrigger, SplitText);
      gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);

      //////////////////////////////////////////////////
      ////////////////  SCROLLING  //////////////////
      const mm = gsap.matchMedia();
      mm.add("(min-width: 768px)", () => {
          //////////////////////////////////////////////////
          // set section heights
          let largestOffset = [];
          let wrapHeight = [];
          let sectionOffsets = (largestOffset, wrapHeight) => {
              let setions = $('.section-3-image');
              let i = 0;
              
              setions.each(function() {
                  let imgCont = $(this).find('.img-cont');
                  wrapHeight[i] = $(this).find('.img-wrap').height();
                  largestOffset[i] = 0;

                  imgCont.each(function() {
                      let offset = $(this).find('.svimg').height();
                      // $(this).attr('data-svoffset', offset);

                      if(offset > largestOffset[i]){
                          largestOffset[i] = offset;
                      }
                  });
                  i++;
              });
          };
          sectionOffsets(largestOffset, wrapHeight);
          console.log(largestOffset);

          let section3Heights = [];
          function setSectionHeight(){
              let setions = $('.section-3-image');
              let i = 0;
              setions.each(function() {
                  section3Heights.push($(this).height());
                  // console.log('height: ' + $(this).height())
                  if( largestOffset[i] > 0){
                      let newSectionHeight = $(this).height() - wrapHeight[i] + largestOffset[i];
                      $(this).height(newSectionHeight);
                  } else{
                      $(this).height($(this).height());
                  }
                  // console.log(newSectionHeight)
                  
                  // $(this).height($(this).height());
                  $(this).find('.section-cont').css('position', 'absolute');
                  i++;
              });
          }
          setTimeout(setSectionHeight, 1000);
          // setSectionHeight();
          
          ScrollTrigger.normalizeScroll(true);
          // create the scrollSmoother before your scrollTriggers
          // smoother = ScrollSmoother.create({
          //     smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
          //     effects: true,           // looks for data-speed and data-lag attributes on elements
          //     // smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          // });

          let smoother = ScrollSmoother.create({
              wrapper: "#smooth-wrapper",
              content: "#smooth-content",
              smooth: 1,
              normalizeScroll: true,
              ignoreMobileResize: true,
              smoothTouch: false, 
              effects: true,
              // onUpdate: (self) => skewSetter(clamp(self.getVelocity() / -150)),
              // onStop: () => skewSetter(0)
            });

          //   smoother.effects('.gsap-lag', {
          //     // lag: 0.1,
          //   })

          // 3 image section
          // const pinSectionThree = $('.section-3-image');
          const pinSectionThrees = gsap.utils.toArray('.section-3-image');
          // pinSectionThrees.forEach(pinSectionThree => {
          //     textCont = pinSectionThree.querySelector('.container-text');
          //     content = pinSectionThree.querySelector('.section-cont');
          //     // console.log(textCont)

          //     gsap.to(content, {
          //         marginTop: '40vh',
          //         scrollTrigger: {
          //             trigger: pinSectionThree,
          //             start: 'top top',
          //             // end: () => `+=${pinnedElement.clientHeight}`,
          //             end: 'bottom top',
          //             // pin: pinSectionThree,
          //             pinSpacing: false,
          //             markers: false, // For visualization (can be removed)
          //             scrub: true,
          //         },
          //     });
          //     // gsap.to(pinSectionThree, {
          //     //     // top: '-50vh',
          //     //     scrollTrigger: {
          //     //         trigger: pinSectionThree,
          //     //         start: 'top top',
          //     //         // end: () => `+=${pinnedElement.clientHeight}`,
          //     //         end: 'bottom 70%',
          //     //         pin: pinSectionThree,
          //     //         pinSpacing: false,
          //     //         markers: true, // For visualization (can be removed)
          //     //         scrub: true,
          //     //     },
          //     // });


          // })


          const section3ImageAnim = gsap.utils.toArray('.section-3-image .img-cont');
          // let imgSectionThree;
          // let textCont;
          section3ImageAnim.forEach(imgAnim => {
              // textCont = pinSectionThree.querySelector('.container-text');
              // console.log(textCont)
              // console.log(imgAnim)
              let pTrigger = imgAnim.closest('.section-3-image');
              pTrigger = imgAnim.closest('.img-wrap');
              // let offset = $(imgAnim).attr('data-offset') + 'px'
              // console.log(offset)
              console.log(pTrigger);
              // let offset = '400px'

              gsap.to(imgAnim, {
                  marginTop: 0,
                  ease: 'power1.out',
                  ease: 'none',
                  scrollTrigger: {
                      trigger: pTrigger,
                      start: 'top bottom',
                      // end: () => `+=${pinnedElement.clientHeight}`,
                      end: 'top 50%',
                      // end: 'bottom 100%',
                      // pin: imgAnim,
                      pinSpacing: false,
                      markers: false, // For visualization (can be removed)
                      scrub: true,
                  },
              });

              // gsap.to(imgAnim, {
              //     top: '0',
              //     scrollTrigger: {
              //         trigger: imgAnim,
              //         start: 'top bottom',
              //         // end: () => `+=${pinnedElement.clientHeight}`,
              //         end: 'bottom 70%',
              //         // pin: imgAnim,
              //         pinSpacing: false,
              //         markers: true, // For visualization (can be removed)
              //         scrub: true,
              //     },
              // });
          });

          ////////////////////////////////////////////
          // 2 image section text pin
          const pinSectionTwo = $('.section-2-image');
          const textSectionTwo = $('.section-2-image .container-text');
          gsap.to(textSectionTwo, {
              // top: '-50vh',
              scrollTrigger: {
                  trigger: pinSectionTwo,
                  start: 'top top',
                  // end: () => `+=${pinnedElement.clientHeight}`,
                  end: 'bottom 70%',
                  pin: textSectionTwo,
                  pinSpacing: false,
                  markers: false, // For visualization (can be removed)
                  scrub: true,
              },
          });

          ////////////////////////////////////////////
          // all image skew
          // const imgSkews = gsap.utils.toArray('.gsap-skew');
          // imgSkews.forEach(img => {
          //     ScrollTrigger.create({
          //         trigger: img,
          //         start: 'top bottom',
          //         end: 'bottom top',
          //         scrub: true,
          //         onUpdate({getVelocity}){
          //             gsap.fromTo(img, {
          //                 skewY: `${getVelocity() / 300}deg`
          //               }, {
          //                 skewY: 0
          //               })
          //         }
          //     })

          // })


          // // 2 image section
          // const pinSection = document.getElementById('future-thrive');
          // const imgSection = $('#future-thrive .img-wrap');
          // gsap.to(imgSection, {
          //     top: '-50vh',
          //     scrollTrigger: {
          //         trigger: pinSection,
          //         start: 'top top',
          //         // end: () => `+=${pinnedElement.clientHeight}`,
          //         end: 'bottom 70%',
          //         pin: pinSection,
          //         pinSpacing: false,
          //         markers: false, // For visualization (can be removed)
          //         scrub: false,
          //     },
          // });


          // // 3 image section
          // // const pinSectionThree = $('.section-3-image');
          // const pinSectionThrees = gsap.utils.toArray('.section-3-image');
          // let imgSectionThree;
          // pinSectionThrees.forEach(pinSectionThree => {
          //     imgSectionThree = pinSectionThree.querySelector('.img-wrap');

          //     gsap.to(imgSectionThree, {
          //         top: '-50vh',
          //         scrollTrigger: {
          //             trigger: pinSectionThree,
          //             start: 'top top',
          //             // end: () => `+=${pinnedElement.clientHeight}`,
          //             end: 'bottom 70%',
          //             pin: pinSectionThree,
          //             pinSpacing: false,
          //             markers: false, // For visualization (can be removed)
          //             scrub: true,
          //         },
          //     });
          // })

          // // 3 image section
          // const pinSectionThree = $('.section-3-image')
          // const imgSectionThree = $('.section-3-image .img-wrap');
          // gsap.to(imgSectionThree, {
          //     top: '-50vh',
          //     scrollTrigger: {
          //         trigger: pinSectionThree,
          //         start: 'top top',
          //         // end: () => `+=${pinnedElement.clientHeight}`,
          //         end: 'bottom 70%',
          //         pin: pinSectionThree,
          //         pinSpacing: false,
          //         markers: false, // For visualization (can be removed)
          //         scrub: true,
          //     },
          // });

          return () => smoother.kill();
      });

      // create the scrollSmoother before your scrollTriggers
      // smoother = ScrollSmoother.create({
      //     smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
      //     effects: false,           // looks for data-speed and data-lag attributes on elements
      //     // smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      // });

      mm.add("(max-width: 768px)", () => {
          // create the scrollSmoother before your scrollTriggers
          // let smoother = ScrollSmoother.create({
          //     smooth: false,               // how long (in seconds) it takes to "catch up" to the native scroll position
          //     effects: false,           // looks for data-speed and data-lag attributes on elements
          //     smoothTouch: false,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          // });

          // smoother.effects('.gsap-m-parallax img', {
          //     speed: 0.9,
          // })
      });
      

      // // Get a reference to the image container element
      // const imgContainer = document.querySelector('.img-cont');

      // // Calculate the parallax offset based on animation speed
      // const parallaxOffset = -(window.innerHeight * animation_speed);

      // // Create a GSAP animation for the parallax effect
      // gsap.to(imgContainer, {
      //     y: parallaxOffset,
      //     ease: 'none',
      //     scrollTrigger: {
      //         trigger: imgContainer,
      //         start: 'top top',
      //         end: 'bottom top',
      //         scrub: true,
      //     },
      // });

      //////////////////////////////////////////////////
      ////////////////  NAV BAR  //////////////////

      ///////////////////////////
      // nav bar pin top
      const pinnedElement = $('#primary-nav');
      gsap.to(pinnedElement, {
          scrollTrigger: {
              trigger: pinnedElement,
              start: 'top top',
              // end: () => `+=${pinnedElement.clientHeight}`,
              endTrigger: 'footer', // Release pin when the last container is reached
              end: 'bottom bottom',
              pin: true,
              pinSpacing: false,
              markers: false, // For visualization (can be removed)
          },
      });

      //////////////////////////
      // nav bar bg animation
      const primNav = $('#primary-nav');
      const navOffset = (primNav.outerHeight() / $(window).height() ) * 100 ;
      const navBgs = gsap.utils.toArray('.nav-bg');
      const threeImgSections = gsap.utils.toArray('.section-bg');
      let navBgTrigger;
      let navBgTl;
      navBgs.forEach((navBg, i) => {
          // textCont = pinSectionThree.querySelector('.container-text');
          // console.log(textCont)
          navBgTrigger = threeImgSections[i];
          navBgTl = gsap.timeline({
              scrollTrigger: {
                  trigger: navBgTrigger,
                  // start: 'top 1%',
                  start: 'top ' + navOffset + '%',
                  // end: () => `+=${pinnedElement.clientHeight}`,
                  end: 'top top',
                  scrub: true,
                  markers: false,
              },
          });
          navBgTl.fromTo(
              navBg,
              {
                  clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
              },
              {
                  clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
                  duration: 1,
              }
          );

      });

      // animate primary nav visibility
      let navLeftTl = gsap.timeline({
          scrollTrigger: {
              trigger: '.section-2-image',
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navLeftTl.to(
          primNav,
          {
              clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
              // duration: 1,
          }
      );

      // animate primary nav visibility
      let navBlueTlIn = gsap.timeline({
          scrollTrigger: {
              trigger: threeImgSections[1],
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navBlueTlIn.to(
          '.container-nav-2',
          {
              clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
          }
      );

      // animate primary nav visibility
      let navBlueTlOut = gsap.timeline({
          scrollTrigger: {
              trigger: threeImgSections[2],
              // start: 'top 1%',
              start: 'top ' + navOffset + '%',
              // end: () => `+=${pinnedElement.clientHeight}`,
              end: 'top top',
              scrub: true,
              markers: false,
              // pin: true,
          },
      });
      navBlueTlOut.to(
          '.container-nav-2',
          {
              clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
              // duration: 1,
          }
      );
      let tl;
      let sliderImgOpacity = '0.5';
      

      //////////////////////////////////////////////////
      // slider expand
      function on_load(){
          tl = gsap.timeline({ paused: false, defaults: {  } });
          tl.delay(.1); 
          // const container = document.querySelector('.slide-0')
          tl.to('.slide-0', { opacity: "1" }, ""); // expand
          tl.to('.slide-0', { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" }, "+=.2"); // expand
          // tl.to('.slide-0', { opacity: "1" }, ""); // expand
          tl.to('.slide-0 img', { opacity: sliderImgOpacity }, "+=.1");
          // callCount++;
      }
      on_load();


      ////////////////////////////////////////
      // slider overlay animation
      // Initialize the animation
      function animateOverlay(){
          // make text visible after load
          document.getElementById('slider-overlay').style.opacity = "1";

          // declar vars
          const titleSplitTypes = document.querySelectorAll('.title-reveal-type');
          const title = new SplitText('#hero-title', {
              types: 'chars'
          });
          const logoIcon = document.querySelector('.logo-icon');
          const overlayTl = gsap.timeline();

          mm.add("(min-width: 768px)", () => {
              // set title left position
              const titleLeftPos = ( $(document).width() - $('#hero-title').width() ) / 2;
              $('#hero-title').css('left', titleLeftPos + 'px');
          });

          overlayTl.delay(.25);

          // animate icon in
          overlayTl.from(logoIcon, {
              // y: 30,
              opacity: 0,
              duration: .75,
              ease: 'power4.out'
          });

          // animate text up and in
          const titleTl = gsap.timeline();
          titleTl.from('#hero-title', {
              // y: 30,
              opacity: 0,
              // stagger: 0.05,
              duration: 1,
              ease: 'power4.out'
          });
          overlayTl.add(titleTl);

                  
          ///////////////////////////////////////////////////
          ////////////////  HERO LOGO  //////////////////

          mm.add("(min-width: 768px)", () => {
              ScrollTrigger.create({
                  // trigger: '#primary-nav',
                  trigger: '.section-2-image',
                  start: "top 80%",
                  end: "top " + navOffset + "%",
                  scrub: true,
                  markers: false,
                  // pin: true,
                  animation: gsap.to(logoIcon, {
                      width: '30px',
                  }
                  ),
              });
              ScrollTrigger.create({
                  trigger: '#logo-cont',
                  start: 'top top',
                  // end: 'bottom 100000%',
                  end: document.body.scrollHeight,
                  scrub: true,
                  markers: false,
                  pin: true,
                  pinSpacing: false,
              });
          });


          
          ///////////////////////////////////////////////////
          ////////////////  HERO TITLE  //////////////////

          let leftPos = $('.nav-left').offset().left;
          let startFontSize = $('#hero-title').css('font-size');
          let startLeftPos = $('#hero-title').offset().left;
          const heroTitle = document.getElementById('hero-title');

          mm.add("(min-width: 768px)", () => {

              ScrollTrigger.create({
                  trigger: '.section-2-image',
                  start: "top 80%",
                  // end: "bottom top",
                  end: "top " + navOffset + "%",
                  scrub: true,
                  markers: false,
                  animation: gsap.fromTo(heroTitle, {
                      fontSize: startFontSize,
                      left: startLeftPos + "px",
                  },
                  {
                      fontSize: "2rem",
                      left: leftPos + "px",
                      // top: '20px',
                  }),
              });

              ScrollTrigger.create({
                  trigger: '#hero-title-cont',
                  start: '55% top',
                  end: 'bottom -100%',
                  // end: 'bottom ' + '-' + navOffset + '%',
                  // end: 'bottom ' + navOffset + '%',
                  scrub: true,
                  markers: false,
                  pin: true,
                  pinSpacing: false,
              });
          });
      }
      // animateOverlay();
      setTimeout(animateOverlay, 2000);




  });

}));
